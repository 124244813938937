<template>
  <div class="footer-main">
    <div class="footer-contant">
      <div class="left-side">
        <div class="project-logo">
          <img src="../assets/logo/logo.png" alt="" @click="indexRouter" />
        </div>
        <div class="social-logo">
          <img src="../assets/logo/twitter.png" class="twitter" />
          <img src="../assets/logo/telegram.png" class="telegram" />
          <img src="../assets/logo/discard.png" class="discard" />
          <img src="../assets/logo/mlogo.png" class="mlogo" />
        </div>
        <div class="copyright fs18 pr400 fc949798">
          Copyright © 2021-2022, 《Multiverse》. All rights reserved.
        </div>
      </div>
      <div class="right-side">
        <div class="help-center fs16 pr400 fc151517">
          <div class="help">Help Center</div>
          <div class="gitbook">Gitbook</div>
          <div class="faqs">FAQS</div>
          <div class="audit">Audit Report</div>
        </div>
        <div class="email">
          <input
            type="text"
            placeholder="Your email"
            class="fs16 pr400 fc151517"
          />
          <button class="fs16 pr400 fcFFFFFF">Subscribe</button>
        </div>
        <div class="email-desc copyright fs16 pr400 fc949798">
          Breaking news about the activities, airdrops and new developments of
          Multiverse sent <br />
          directly to your inbox. Or contact us directly service@xmultiverse.org
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router'

const router = new useRouter()

const indexRouter = () => {
  router.push('/')
}
</script>
<style lang="less" scoped>
.footer-main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 520px;
  background-image: url("../assets/img/footer-back-img.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .footer-contant {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 1400px;
    height: 260px;
    .left-side {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 50%;
      height: 100%;
      .project-logo {
        width: 202px;
        height: 47.19px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .social-logo {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 60px;
        .twitter {
          width: 48px;
          height: 39px;
          cursor: pointer;
        }
        .telegram {
          margin-left: 56px;
          width: 48px;
          height: 40px;
          cursor: pointer;
        }
        .discard {
          margin-left: 56px;
          width: 48px;
          height: 36px;
          cursor: pointer;
        }
        .mlogo {
          margin-left: 56px;
          width: 48px;
          height: 40px;
          cursor: pointer;
        }
      }
    }
    .right-side {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 50%;
      height: 100%;
      .help-center {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-self: flex-end;
        width: 90%;
        .help {
          cursor: pointer;
        }
        .gitbook {
          cursor: pointer;
        }
        .faqs {
          cursor: pointer;
        }
        .audit {
          cursor: pointer;
        }
      }
      .email {
        align-self: flex-end;
        position: relative;
        width: 518px;
        height: 60px;
        margin-top: 80px;
        input {
          padding-left: 19px;
          width: 518px;
          height: 60px;
          outline: none;
          border-radius: 10px;
          border: 1px solid #ff3efd;
          box-sizing: border-box;
        }
        button {
          position: absolute;
          right: 5px;
          top: 5px;
          width: 129px;
          height: 50px;
          background: #dc19da;
          border-radius: 10px;
          border: none;
        }
      }
      .email-desc {
        align-self: flex-end;
        line-height: 22px;
      }
    }
  }
}
</style>
