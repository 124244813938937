<template>
  <Header></Header>
  <div class="index-main">
    {{ sum }}
  </div>
  <Footer></Footer>
  <!-- 网络错误弹窗 -->
  <NetError></NetError>
</template>
<script setup>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import NetError from '../../components/dialog/NetError.vue'
import { getCurrentInstance, onMounted } from 'vue'
import { useStore } from 'vuex'

import { walletIsIntall, walletNetVersion, walletAddress } from '../../wallet/wallet'

const { proxy } = getCurrentInstance()
const store = new useStore()

onMounted(() => {
  getInitWallet()
})
localStorage.setItem('user', 456789)

// const sum = store.state.user.sum

const getInitWallet = async () => {
  const accountIsIntall = walletIsIntall()
  const accountNetVersion = walletNetVersion() //false:测试网 true:主网
  const account = await walletAddress()
  console.log('用户是否安装钱包:', accountIsIntall)
  console.log('用户网络连接链路:', accountNetVersion)
  console.log('用户地址:', account)
  store.commit('user/account', account)
}
</script>
<style lang="less" scoped>
.index-main {
  margin-top: 90px;
  width: 100%;
  height: 800px;
}
</style>
