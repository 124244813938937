<template>
  <Header></Header>
  <div class="purchase-main">
    <div class="back pp600 fs28 fc000000" @click="backClick">
      <img src="../../assets/icon/left-arraw.png" alt="" /> Back
    </div>
    <div class="list-sale pp600 fs44 fc000000">List item for sale</div>
    <div class="contant">
      <div class="nft-img">
        <img src="../../assets/img/sale-nft.png" alt="" />
      </div>
      <div class="input-price">
        <div class="user-name-img pp500 fs16 fc000000">
          <img src="../../assets/img/sale-img.png" alt="" />Cheers UP
        </div>
        <div class="nft-name pp600 fs28 fc000000">Cheers UP #2953</div>
        <div class="price-title pp500 fs16 fc000000">Price</div>
        <div class="input-number">
          <div class="coin">
            <div class="coin-img"></div>
            <div class="coin-name fs20 pm500 fc151517">Eth</div>
            <div class="arrow-img">
              <img src="../../assets/icon/right-arrow.png" alt="" />
            </div>
          </div>
          <div class="address">
            <input
              type="text"
              placeholder="Amount"
              class="fs20 pm500 fc151517"
              v-model="paymentPrice"
            />
          </div>
        </div>
        <div class="line"></div>
        <div class="fees-title pp500 fs16 fc000000">Fees</div>
        <div class="service-fee">
          <div class="title pp300 fs14 fc151517">Service Fee</div>
          <div class="number pp500 fs14 fc000000">2.5 %</div>
        </div>
        <div class="creator-fee">
          <div class="title pp300 fs14 fc151517">Creator Fee</div>
          <div class="number pp500 fs14 fc000000">10.5 %</div>
        </div>
      </div>
    </div>
    <div class="complete">
      <button
        class="pp600 fs20 fc000000"
        :class="btnColorFlag"
        @click="completeClick"
      >
        <span>Complete listing</span>
      </button>
    </div>
  </div>
</template>
<script setup>
import Header from '../../components/Header.vue'
import { useRouter } from 'vue-router'
import { ref, watch } from 'vue'

const router = new useRouter()

// 支付金额
const paymentPrice = ref()
// 确认支付按钮样式是否变化
const btnColorFlag = ref('')
watch(paymentPrice, (newValue, oldValue) => {
  if (newValue) {
    btnColorFlag.value = 'com-btn-bgc'
  } else {
    btnColorFlag.value = ''
  }
})

// 确认提交按钮
const completeClick = () => {
  if (paymentPrice.value) {
    console.log('可以提交')
  } else {
    console.log('请输入价格')
  }
}

const backClick = () => {
  router.back()
}
</script>
<style lang="less" scoped>
@import "../../assets/less/index.less";
.purchase-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  margin-top: 180px;
  width: 1200px;
  height: 100%;
  .back {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 138px;
    height: 50px;
    cursor: pointer;
    img {
      margin-right: 17px;
    }
  }
  .list-sale {
    margin-top: 50px;
    width: 368px;
    height: 48px;
  }
  .contant {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 37px;
    width: 100%;
    height: 395px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    box-sizing: border-box;
    .nft-img {
      margin-left: 32px;
      width: 331px;
      height: 361px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .input-price {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 32px;
      width: 773px;
      height: 361px;
      .user-name-img {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        img {
          margin-right: 8px;
          width: 36px;
          height: 36px;
        }
      }
      .nft-name {
        margin-top: 16px;
      }
      .price-title {
        margin-top: 32px;
      }
      .input-number {
        width: 100%;
        height: 60px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 16px;
        .coin {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
          width: 127px;
          height: 50px;
          background: #ffffff;
          border-radius: 10px 10px 10px 10px;
          border: 1px solid #ececec;
          .coin-img {
            width: 40px;
            height: 40px;
            background: #949798;
            opacity: 0.4;
            border-radius: 50%;
          }
          .arrow-img {
            width: 8px;
            height: 21px;
            img {
              width: 100%;
              height: 14px;
            }
          }
        }
        .address {
          margin-left: 32px;
          width: 363px;
          height: 50px;
          input {
            padding-left: 24px;
            width: 100%;
            height: 100%;
            background: #ffffff;
            border-radius: 10px 10px 10px 10px;
            border: 1px solid #ececec;
            outline: none;
          }
        }
      }
      .line {
        margin-top: 26px;
        width: 100%;
        height: 1px;
        background: #000000;
        opacity: 0.1;
      }
      .fees-title {
        margin-top: 26px;
      }
      .service-fee {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 16px;
      }
      .creator-fee {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 12px;
      }
    }
  }
  .complete {
    margin: 0 auto;
    margin-top: 48px;
    margin-bottom: 172px;
    button {
      width: 292px;
      height: 66px;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      border: none;
      span {
        opacity: 0.3;
      }
    }
    .com-btn-bgc {
      .fs20;
      .pp600;
      .fcFFFFFF;
      background: linear-gradient(132deg, #ff70fe 0%, #806bff 100%);
      span {
        opacity: 1;
      }
    }
  }
}
</style>
