<template>
  <!-- 支付弹窗 -->
  <div class="payment-dialog" v-show="paymentDialogShow">
    <div class="main">
      <!-- 关闭按钮 -->
      <div class="close">
        <img src="../../assets/icon/close.png" alt="" @click="closeClick" />
      </div>
      <!-- 标题 -->
      <div class="complete-title pp600 fs36 fc000000">
        Complete your listing
      </div>
      <!-- 商品详细信息 -->
      <div class="items-desc">
        <div class="nft-img-name-price">
          <div class="nft-img">
            <img src="../../assets/img/sale-nft.png" alt="" />
          </div>
          <div class="detail">
            <div class="top pp500 fs14 fc000000">
              <img src="../../assets/img/user-head.png" alt="" />Cheers UP
            </div>
            <div class="middle">
              <div class="left pp600 fs16 fc000000">Cheers UP #2953</div>
              <div class="right">
                <div class="img"></div>
                <div class="pp600 fs16 fc000000">5</div>
              </div>
            </div>
            <div class="bottom">
              <div class="left pp400 fs14 fc000000">Quantity:1</div>
              <div class="right pp400 fs14 fc000000">$4,345.18 USD</div>
            </div>
          </div>
        </div>
        <div class="nft-time">
          <div class="scheduled pp600 fs14 fc000000">SCHEDULED FOR</div>
          <div class="month">
            <div class="title pp600 fs14 fc000000">1 month</div>
            <div class="detail pp300 fs14 fc000000">
              Jun 23, 2022 6:45 PM - Jul 23, 2022 6:45 PM
            </div>
          </div>
        </div>
      </div>
      <!-- 描述 -->
      <div class="describe pp400 fs14 fc000000">
        Your wallet balance is below 0.05 ETH. The next steps require small
        transaction fees,so you may have to deposit additional funds.
      </div>
      <div class="line"></div>
      <!-- 授权按钮 -->
      <div class="approve-nft">
        <div class="number">
          <img src="../../assets/icon/step-one.png" alt="" />
        </div>
        <div class="approve-main">
          <div class="title pp600 fs18 fc000000">Approve NFT</div>
          <div class="detail pp300 fs14 fc000000">
            We’ll ask your approval for the marketplace to access your token.
            This is a one-time only operation.
          </div>
          <div class="approve">
            <button class="pp600 fs14 fcFFFFFF">Approve</button>
          </div>
        </div>
      </div>
      <!-- 确认按钮 -->
      <div class="confirm-listing">
        <div class="number">
          <img src="../../assets/icon/step-two.png" alt="" />
        </div>
        <div class="approve-main">
          <div class="title pp600 fs18 fc000000">Confirm Listing</div>
          <div class="approve">
            <button class="pp600 fs14 fcFFFFFF" @click="payComplete">
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 支付完成弹窗 -->
  <PaymentCompleted
    :paymentComplete="paymentComplete"
    @payCompleteClose="payCompleteClose"
  ></PaymentCompleted>
</template>
<script setup>
import { ref } from 'vue'
import PaymentCompleted from './PaymentCompleted.vue' //支付完成弹窗

const props = defineProps(['paymentDialogShow'])
const emits = defineEmits(['buyNowClose'])

const closeClick = () => {
  emits('buyNowClose')
}

// 支付完成 展示购买商品详情
const paymentComplete = ref(false)
const payComplete = () => {
  paymentComplete.value = true
  emits('buyNowClose')
}
const payCompleteClose = () => {
  paymentComplete.value = false
}



</script>
<style lang="less" scoped>
.payment-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  .main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-293px, -368.5px);
    padding-left: 32px;
    width: 586px;
    height: 737px;
    background: #ffffff;
    box-shadow: 7px 7px 26px 5px rgba(109, 109, 109, 0.08);
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    .close {
      align-self: flex-end;
      margin: 30px 28px 0 0;
      img {
        width: 26px;
        height: 26px;
        cursor: pointer;
      }
    }
    .items-desc {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 23px;
      width: 522px;
      height: 208px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 8px 8px 0px 0px;
      .nft-img-name-price {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        .nft-img {
          width: 120px;
          height: 120px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .detail {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          margin-left: 22px;
          width: 355px;
          height: 100%;
          .top {
            img {
              margin-right: 8px;
              width: 24px;
              height: 24px;
            }
          }
          .middle {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .right {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              .img {
                margin-right: 8px;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background-color: gray;
              }
            }
          }
          .bottom {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .left {
              opacity: 0.3;
            }
            .right {
              opacity: 0.3;
            }
          }
        }
      }
      .nft-time {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        height: 88px;
        .scheduled {
          margin-left: 32px;
          width: 120px;
          height: 100%;
          line-height: 88px;
        }
        .month {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-left: 69px;
          width: 290px;
          height: 100%;
          .title {
            margin-top: 25px;
          }
          .detail {
            margin-top: 12px;
          }
        }
      }
    }
    .describe {
      margin-top: 32px;
      width: 522px;
      text-align: center;
    }
    .line {
      margin-top: 32px;
      width: 522px;
      height: 1px;
      background-color: gray;
    }
    .approve-nft {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-top: 32px;
      width: 522px;
      height: 127px;
      .number {
        width: 28px;
        height: 28px;
        img {
          width: 28px;
          height: 28px;
        }
      }
      .approve-main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 14px;
        .detail {
          width: 393px;
          height: 42px;
        }
        .approve {
          width: 125px;
          height: 44px;
          button {
            width: 100%;
            height: 100%;
            background: linear-gradient(133deg, #ff3efd 0%, #9b8bff 100%);
            border-radius: 10px 10px 10px 10px;
            opacity: 1;
            border: none;
          }
        }
      }
    }
    .confirm-listing {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-top: 32px;
      width: 522px;
      height: 77px;
      .number {
        width: 28px;
        height: 28px;
        .img {
          width: 28px;
          height: 28px;
        }
      }
      .approve-main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 14px;
        .detail {
          width: 393px;
          height: 42px;
        }
        .approve {
          width: 125px;
          height: 44px;
          button {
            width: 100%;
            height: 100%;
            background: linear-gradient(133deg, #ff3efd 0%, #9b8bff 100%);
            border-radius: 10px 10px 10px 10px;
            opacity: 1;
            border: none;
          }
        }
      }
    }
  }
}
</style>
