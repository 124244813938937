<template>
  <!-- 网站授权页面 -->
  <Header></Header>
  <div class="login-main">
    <div class="contract-address fs48 pbbold fc151517">
      Enter Your Contract Address
    </div>
    <div class="contant">
      <div class="describe fs16 pr400 fc949798">
        what is the address of your erc721 or erc1155 contract on themainnet
        network?
      </div>
      <div class="wallet-address">
        <div class="coin">
          <div class="coin-img"></div>
          <div class="coin-name fs20 pm500 fc151517">{{ iconName }}</div>
          <div class="arrow-img">
            <img src="../../assets/icon/right-arrow.png" alt="" />
          </div>
        </div>
        <div class="address">
          <input
            type="text"
            class="fs20 pm500 fc151517"
            v-model="walletAddress"
          />
        </div>
      </div>
      <div class="submit">
        <button class="fs20 ps400 fcFFFFFF">submit</button>
      </div>
    </div>
  </div>
</template>
<script setup>
import Header from '../../components/Header.vue'
import { ref } from 'vue'
// 币种名称
const iconName = ref('Ethereum')
// 币种图片
const iconImg = ref('')
// 用户钱包地址
const walletAddress = ref(123456)

</script>
<style lang="less" scoped>
.login-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  .contract-address {
    margin-top: 183px;
    height: 79px;
  }
  .contant {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 59px;
    width: 1000px;
    height: 400px;
    background: #ffffff;
    box-shadow: 7px 7px 26px 5px rgba(109, 109, 109, 0.08);
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #ececec;
    .describe {
      margin-top: 77px;
      height: 39px;
    }
    .wallet-address {
      width: 100%;
      height: 60px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-top: 30px;
      .coin {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin-left: 83px;
        width: 216px;
        height: 60px;
        background: #ffffff;
        border-radius: 10px 10px 10px 10px;
        border: 1px solid #ececec;
        .coin-img {
          width: 40px;
          height: 40px;
          background: #949798;
          opacity: 0.4;
          border-radius: 50%;
        }
        .arrow-img {
          width: 8px;
          height: 21px;
          img {
            width: 100%;
            height: 14px;
          }
        }
      }
      .address {
        margin-left: 32px;
        width: 586px;
        height: 60px;
        input {
          padding-left: 24px;
          width: 100%;
          height: 100%;
          background: #ffffff;
          border-radius: 10px 10px 10px 10px;
          border: 1px solid #ececec;
          outline: none;
        }
      }
    }
    .submit {
      margin-top: 75px;
      button {
        width: 198px;
        height: 52px;
        background: linear-gradient(132deg, #ff70fe 0%, #806bff 100%);
        border-radius: 10px 10px 10px 10px;
        border: none;
      }
    }
  }
}
</style>
