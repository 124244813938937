// 引入element UI 通知组件
import 'element-plus/es/components/message/style/css'
import { ElMessage } from 'element-plus'
import { ethers } from 'ethers';

// 检测钱包是否安装
export function walletIsIntall() {
  if (typeof window.ethereum !== 'undefined') {
    ElMessage.success('用户钱包已安装')
  } else {
    ElMessage.error('用户钱包未安装')
    return false
  }
  return true
}

// 检测钱包网络 97:测试网 56:主网 
export function walletNetVersion() {
  const networkVersion = window.ethereum.networkVersion
  if (networkVersion == '56') {
    ElMessage.success('网络连接为主网')
  } else {
    ElMessage.error('网络连接为测试网')
    return false
  }
  return true
}

// 获取钱包地址
export async function walletAddress() {
  const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
  if (accounts[0]) {
    ElMessage.success('连接钱包成功')
  } else {
    ElMessage.error('您拒绝连接钱包')
    return false
  }
  const account = ethers.utils.getAddress(accounts[0]);
  return account
}