<template>
  <!-- 顶部导航栏 -->
  <div class="header-nav">
    <div class="nav-main">
      <!-- 左侧 市场 排名 奖励 搜索 -->
      <div class="left-side">
        <div class="logo-area">
          <img src="../assets/logo/logo.png" alt="" @click="indexRouter" />
        </div>
        <div class="explore-area pm500 fc151517 fs18" @click="exploreRouter">
          Explore
        </div>
        <div class="rankings-area pm500 fc151517 fs18" @click="rankingRouter">
          Rankings
        </div>
        <div class="rewards-area pm500 fc151517 fs18">Rewards</div>
        <div class="search-area">
          <input type="text" placeholder="Search" class="ps400 fs16 fc949798" />
          <img src="../assets/icon/search.png" alt="" />
        </div>
      </div>
      <!-- 语言 主题 通知 个人中心 -->
      <div class="right-side">
        <div class="language-area" @click="languageRouter">
          <img src="../assets/icon/language.png" alt="" />
        </div>
        <div class="theme-area">
          <img src="../assets/icon/theme.png" alt="" />
        </div>
        <div class="notice-area">
          <img src="../assets/icon/notice.png" alt="" />
        </div>
        <div class="user-area">
          <img src="../assets/icon/default-head.png" @click="userRouter" />
          <div class="user-dialog" v-show="userDialogIsShow">
            <PersonDialog @userRouter="userRouter"></PersonDialog>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import PersonDialog from './dialog/PersonDialog.vue'

const router = new useRouter()

// 点击头像 弹窗是否展示
const userDialogIsShow = ref(false)

const languageRouter = () => {
  router.push('/login')
}
const indexRouter = () => {
  router.push('/')
}
const exploreRouter = () => {
  router.push('/market')
}
const rankingRouter = () => {
  router.push('')
}
const userRouter = () => {
  userDialogIsShow.value = !userDialogIsShow.value
}
</script>
<style lang="less" scoped>
.header-nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 90px;
  background-image: url("../assets/img/head-back-img.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .nav-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 1500px;
    height: 100%;
    .left-side {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 65%;
      height: 100%;
      .logo-area {
        width: 202px;
        height: 47.19px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .explore-area {
        cursor: pointer;
      }
      .rankings-area {
        cursor: pointer;
      }
      .rewards-area {
        cursor: pointer;
      }
      .search-area {
        position: relative;
        width: 35%;
        height: 45px;
        input {
          padding-left: 57px;
          width: 100%;
          height: 100%;
          outline: none;
          border-radius: 10px;
          border: 1px solid #151517;
          box-sizing: border-box;
        }
        img {
          position: absolute;
          left: 15px;
          top: 11px;
          width: 22px;
          height: 22px;
        }
      }
    }
    .right-side {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 17%;
      height: 100%;
      .language-area {
        width: 26px;
        height: 25.13px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .theme-area {
        width: 26px;
        height: 26px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .notice-area {
        width: 23.4px;
        height: 26px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .user-area {
        position: relative;
        width: 26px;
        height: 26px;
        img {
          width: 26px;
          height: 26px;
          cursor: pointer;
        }
        .user-dialog {
          display: flex;
          flex-direction: row;
          justify-content: center;
          position: absolute;
          top: 42px;
          right: -8px;
          width: 390px;
          height: 700px;
          background: rgba(255, 255, 255, 0.8);
          box-shadow: 0px 16px 60px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(12px);
          border-radius: 16px;
        }
      }
    }
  }
}
</style>