<template>
  <Header></Header>
  <!-- 用户默认背景图 -->
  <div class="person-default-img">
    <img src="../../assets/img/person-default.png" alt="" />
  </div>
  <!-- 用户头像、姓名和地址 -->
  <div class="user-info">
    <div class="head-img">
      <img src="../../assets/img/user-head.png" alt="" />
    </div>
    <div class="name pp600 fs32 fc000000">{{ userName }}</div>
    <div class="address pp300 fs16 fc000000">{{ userAddress }}</div>
  </div>
  <div class="person-main">
    <!-- 我的持有 我的喜爱 我的收藏 活动 导航栏 -->
    <div class="person-nav fs18 pm500 fc151517">
      <div
        class="sport"
        @click="nftItemRouter('collection')"
        :class="typeIsActive == 'collection' ? 'typeActive' : ''"
      >
        My collection
      </div>
      <div
        class="trend"
        @click="nftItemRouter('items')"
        :class="typeIsActive == 'items' ? 'typeActive' : ''"
      >
        My items
      </div>
      <div
        class="top"
        @click="nftItemRouter('favorited')"
        :class="typeIsActive == 'favorited' ? 'typeActive' : ''"
      >
        Favorited
      </div>
      <div
        class="art"
        @click="nftItemRouter('activity')"
        :class="typeIsActive == 'activity' ? 'typeActive' : ''"
      >
        Activity
      </div>
    </div>
    <!-- 筛选 -->
    <div class="filter-data">
      <div class="filter-btn pm500 fs20 fc151517">
        <img src="../../assets/icon/filter.png" alt="" />
        Filter
      </div>
      <div class="search">
        <input type="text" placeholder="Search" class="pm500 fs20 fc949798" />
      </div>
      <div class="price-sort pm500 fs20 fc151517">
        Price Low To Heigh
        <img src="../../assets/icon/right-arrow.png" alt="" />
      </div>
    </div>
    <!-- 数据查询展示 -->
    <div class="person-items-show">
      <UserSelectItems></UserSelectItems>
    </div>
  </div>
  <Footer></Footer>
</template>
<script setup>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import UserSelectItems from './components/UserSelectItems.vue'
import { useRoute, useRouter } from 'vue-router'
import { onMounted, ref } from 'vue'

const route = new useRoute()
const router = new useRouter()

onMounted(() => {
  nftItemRouter()
})

// 用户姓名
const userName = ref('User Person')
// 用户钱包地址
const userAddress = ref('0x94Ac...3S1Q')

// 默认查询全部的NFT
const typeIsActive = ref('collection')
// 点击查询哪个模块将该模块导航添加背景颜色，并跳转查询
const nftItemRouter = (type) => {
  switch (type) {
    case 'collection': typeIsActive.value = 'collection'; break
    case 'items': typeIsActive.value = 'items'; break
    case 'favorited': typeIsActive.value = 'favorited'; break
    case 'activity': typeIsActive.value = 'activity'; break
  }
}
</script>
<style lang="less" scoped>
.person-default-img {
  margin-top: 90px;
  width: 100%;
  height: 320px;
  img {
    width: 100%;
    height: 100%;
  }
}
.user-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  .head-img {
    position: absolute;
    top: -90px;
    width: 128px;
    height: 128px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .name {
    margin-top: 53px;
  }
  .address {
    margin-top: 10px;
  }
}
.person-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  width: 1300px;
  height: 100%;
  .person-nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 71px;
    .typeActive {
      background: linear-gradient(132deg, #ff70fe 0%, #806bff 100%);
      border-radius: 10px 10px 10px 10px;
      color: #ffffff;
    }
    .sport {
      padding: 9px 38px 9px 38px;
      cursor: pointer;
    }
    .trend {
      padding: 9px 38px 9px 38px;
      margin-left: 60px;
      cursor: pointer;
    }
    .top {
      padding: 9px 38px 9px 38px;
      margin-left: 60px;
      cursor: pointer;
    }
    .art {
      padding: 9px 38px 9px 38px;
      margin-left: 60px;
      cursor: pointer;
    }
    .collect {
      padding: 9px 38px 9px 38px;
      margin-left: 60px;
      cursor: pointer;
    }
  }
  .filter-data {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 64px;
    width: 100%;
    height: 60px;
    .filter-btn {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 200px;
      height: 60px;
      line-height: 60px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      border: 1px solid #e6e6e6;
      cursor: pointer;
      img {
        margin-left: 21px;
        margin-right: 32px;
        width: 26px;
        height: 28px;
      }
    }
    .search {
      margin-left: 39px;
      width: 727px;
      height: 60px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      input {
        padding-left: 43px;
        width: 100%;
        height: 100%;
        border-radius: 10px 10px 10px 10px;
        border: 1px solid #e6e6e6;
        outline: none;
        box-sizing: border-box;
      }
    }
    .price-sort {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-left: 33px;
      padding-left: 30px;
      width: 300px;
      height: 60px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      border: 1px solid #e6e6e6;
      box-sizing: border-box;
      img {
        margin-left: 60px;
      }
    }
  }
  .person-items-show {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 0 152px 0;
    width: 1300px;
    height: 100%;
  }
}
</style>
