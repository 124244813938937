<template>
  <Header></Header>
  <div class="market-main">
    <div class="title fs48 pbbold fc151517">Explore Collections</div>
    <div class="market-nav fs18 pm500 fc151517">
      <div
        class="sport"
        @click="nftItemRouter('all')"
        :class="typeIsActive == 'all' ? 'typeActive' : ''"
      >
        All
      </div>
      <div
        class="trend"
        @click="nftItemRouter('trending')"
        :class="typeIsActive == 'trending' ? 'typeActive' : ''"
      >
        Trending
      </div>
      <div
        class="top"
        @click="nftItemRouter('top')"
        :class="typeIsActive == 'top' ? 'typeActive' : ''"
      >
        Top
      </div>
      <div
        class="art"
        @click="nftItemRouter('art')"
        :class="typeIsActive == 'art' ? 'typeActive' : ''"
      >
        Art
      </div>
      <div
        class="collect"
        @click="nftItemRouter('collect')"
        :class="typeIsActive == 'collect' ? 'typeActive' : ''"
      >
        Collect
      </div>
    </div>
    <div class="nft-items-show">
      <NftItems></NftItems>
    </div>
  </div>
  <Footer></Footer>
</template>
<script setup>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import NftItems from './components/NftItems.vue'
import { useRoute, useRouter } from 'vue-router'
import { onMounted, ref } from 'vue'

const route = new useRoute()
const router = new useRouter()

onMounted(() => {
  nftItemRouter()
})

// 默认查询全部的NFT
const typeIsActive = ref('all')
// 点击查询哪个模块将该模块导航添加背景颜色，并跳转查询
const nftItemRouter = (type) => {
  switch (type) {
    case 'all': typeIsActive.value = 'all'; break
    case 'trending': typeIsActive.value = 'trending'; break
    case 'top': typeIsActive.value = 'top'; break
    case 'art': typeIsActive.value = 'art'; break
    case 'collect': typeIsActive.value = 'collect'; break
  }
}
</script>
<style lang="less" scoped>
.market-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  margin-top: 180px;
  width: 1300px;
  height: 100%;
  .title {
    align-self: center;
    width: 462px;
    height: 59px;
    line-height: 56px;
  }
  .market-nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 76px;
    .typeActive {
      background: linear-gradient(132deg, #ff70fe 0%, #806bff 100%);
      border-radius: 10px 10px 10px 10px;
      color: #ffffff;
    }
    .sport {
      padding: 9px 38px 9px 38px;
      cursor: pointer;
    }
    .trend {
      padding: 9px 38px 9px 38px;
      margin-left: 60px;
      cursor: pointer;
    }
    .top {
      padding: 9px 38px 9px 38px;
      margin-left: 60px;
      cursor: pointer;
    }
    .art {
      padding: 9px 38px 9px 38px;
      margin-left: 60px;
      cursor: pointer;
    }
    .collect {
      padding: 9px 38px 9px 38px;
      margin-left: 60px;
      cursor: pointer;
    }
  }
  .nft-items-show {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 40px 0 152px 0;
    width: 1300px;
    height: 100%;
  }
}
</style>
