<template>
  <div
    class="items-main"
    v-for="(item, index) in nftItemsData"
    :key="index"
    @click="itemsClick"
  >
    <div class="item-img">
      <img :src="item.itemImg" alt="" />
    </div>
    <div class="item-desc">
      <div class="user-img">
        <img src="../../../assets/img/user-head.png" alt="" />
      </div>
      <div class="item-name fs22 psm600 fc151517">{{ item.itemName }}</div>
      <div class="user-name fs16 pr400 fc949798">by <span>mihedian</span></div>
      <div class="desc fs16 pr400 fc949798">
        <!-- {{ item.description }} -->
        Market support: cash, lage, contracts, derivatives and other
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const router = new useRouter()

const itemsClick = () => {
  router.push('/itemsDetail')
}

// 所有NFT商品详情数据 测试数据
const nftItemsData = ref([
  { itemImg: require('../../../assets/img/nft-item.png'), userImg: require('../../../assets/img/user-head.png'), itemName: 'Win Nft Hero1', userName: 'mihedian', desc: 'Market support: cash, lage, contracts, derivatives and other' },
  { itemImg: require('../../../assets/img/nft-item.png'), userImg: require('../../../assets/img/user-head.png'), itemName: 'Win Nft Hero2', userName: 'mihedian', desc: 'Market support: cash, lage, contracts, derivatives and other' },
  { itemImg: require('../../../assets/img/nft-item.png'), userImg: require('../../../assets/img/user-head.png'), itemName: 'Win Nft Hero3', userName: 'mihedian', desc: 'Market support: cash, lage, contracts, derivatives and other' },
  { itemImg: require('../../../assets/img/nft-item.png'), userImg: require('../../../assets/img/user-head.png'), itemName: 'Win Nft Hero4', userName: 'mihedian', desc: 'Market support: cash, lage, contracts, derivatives and other' },
  { itemImg: require('../../../assets/img/nft-item.png'), userImg: require('../../../assets/img/user-head.png'), itemName: 'Win Nft Hero5', userName: 'mihedian', desc: 'Market support: cash, lage, contracts, derivatives and other' }
])
</script>
<style lang="less" scoped>
.items-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 40px;
  width: 300px;
  height: 420px;
  box-shadow: 7px 7px 26px 5px rgba(109, 109, 109, 0.08);
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #ececec;
  cursor: pointer;
  .item-img {
    width: 301px;
    height: 240px;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px 10px 0px 0px;
      transition: all 1s ease;
      &:hover {
        transform: scale(1.1, 1.1);
      }
    }
  }
  .item-desc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    .user-img {
      position: absolute;
      top: -30px;
      width: 60px;
      height: 60px;
      border: 2px solid #ffffff;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .item-name {
      margin-top: 37px;
    }
    .user-name {
      span {
        color: #dc19da;
      }
    }
    .desc {
      margin-top: 16px;
      width: 264px;
      text-align: center;
    }
  }
}
</style>
