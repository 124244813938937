<template>
  <!-- 交易 用户个人描述组件 -->
  <div class="user-desc-main">
    <div class="user-head-img">
      <img src="../../../assets/img/user-head.png" alt="" />
    </div>
    <div class="user-describe">
      <div class="top-desc fs16 pr400 fc949798">
        Market support: cash, lage, contracts, derivatives and otheMarket
        support: cash, lage, contracts, derivatives and other
      </div>
      <div class="bottom-desc fs16 pr400 fc949798">
        Market support: cash, lage, contracts, derivatives and otheMarket
        support:
      </div>
    </div>
  </div>
</template>
<script setup>

</script>
<style lang="less" scoped>
.user-desc-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .user-head-img {
    width: 60px;
    height: 60px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .user-describe {
    width: 404px;
    height: 100%;
    .top-desc {
    }
    .bottom-desc {
      margin-top: 10px;
    }
  }
}
</style>
