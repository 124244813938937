//求和相关的配置
export default {
  namespaced: true,
  actions: {
  },
  mutations: {
    sum(state, value) {
      state.sum = value
    },
    account(state, value) {
      state.account = value
    }
  },
  state: {
    sum: 1, //当前的和
    account: '' //用户钱包地址
  },
  getters: {
  },
}