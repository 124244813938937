import { $get, $post } from '../utils/request';

export default {
  // NFT商品详情
  nftDetail(data) {
    return $get("metadata/" + data);
  },
  xxx(data) {
    return $post('', data);
  },
};
