<template>
  <!-- 网络错误弹窗 -->
  <div class="main" v-show="netErrorIsShow" @click="netErrorClose">
    <div class="net-error" @click.stop="netErrorOpen">
      <div class="title pp600 fs24 fc000000">
        Wrong Network
        <img src="../../assets/icon/close.png" @click.stop="netErrorClose" />
      </div>
      <div class="error-icon"></div>
      <div class="describe pp400 fs14 fc000000">
        The current network is not supported, please select a supported network
      </div>
      <div class="change-network">
        <button class="testnet pp600 fs16 fcFFFFFF">Testnet</button>
        <button class="mainnet pp600 fs16 fc000000">Mainnet</button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'

// 弹窗是否打开
const netErrorIsShow = ref(false)
const netErrorOpen = () => {
  netErrorIsShow.value = true
}
const netErrorClose = () => {
  netErrorIsShow.value = false
}
</script>
<style lang="less" scoped>
.main {
  position: fixed;
  top: 0;
  left: 0;
  width: 99.3vw;
  height: 99.9vh;
  background: #424242;
  opacity: 0.85;
  z-index: 1;
  .net-error {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-221.5px, -207px);
    width: 443px;
    height: 414px;
    background: #fafbfc;
    border-radius: 16px;
    .title {
      position: relative;
      margin-top: 32px;
      img {
        position: absolute;
        top: 0px;
        right: -100px;
        width: 28px;
        height: 28px;
      }
    }
    .error-icon {
      margin-top: 42px;
      width: 88px;
      height: 88px;
      background: rgba(244, 163, 21, 0.2);
      border-radius: 50%;
    }
    .describe {
      margin-top: 42px;
      width: 330px;
      height: 42px;
      text-align: center;
    }
    .change-network {
      margin-top: 42px;
      .testnet {
        width: 178px;
        height: 50px;
        border-radius: 8px;
        border: none;
        background: linear-gradient(133deg, #ff3efd 0%, #9b8bff 100%);
      }
      .mainnet {
        margin-left: 10px;
        width: 178px;
        height: 50px;
        border-radius: 8px;
        border: none;
      }
    }
  }
}
</style>