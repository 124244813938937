<template>
  <div class="offers-main">
    <div class="fs28 psm600 fc151517 title">offers</div>
    <div class="table-title fs14 pr400 fc949798">
      <div class="price">Price</div>
      <div class="usd-price">USD Price</div>
      <div class="from">From</div>
      <div class="action">Action</div>
    </div>
    <div class="data">
      <div class="data-main" v-for="(item, index) in offers" :key="index">
        <div class="price fs16 pr400 fc151517">
          <div class="price-coin"></div>
          {{ item.price }} ETH
        </div>
        <div class="usd-price fs14 pr400 fc949798">
          ${{ item.changeDollar }}
        </div>
        <div class="from fs14 pr400 fc949798">{{ item.from }}</div>
        <div class="action">
          <button class="action-in fs18 psm600 fc151517">Accept</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive } from 'vue'
const offers = reactive([
  { price: "3.31", changeDollar: '7777.77', from: 'abcdefghijq' },
  { price: "3.32", changeDollar: '7777.77', from: 'abcdefghijq' },
  { price: "3.33", changeDollar: '7777.77', from: 'abcdefghijq' },
  { price: "3.34", changeDollar: '7777.77', from: 'abcdefghijq' },
  { price: "3.35", changeDollar: '7777.77', from: 'abcdefghijq' },
])
</script>
<style lang="less" scoped>
.offers-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 35px;
  width: 100%;
  height: 537px;
  background: #ffffff;
  box-shadow: 7px 7px 26px 5px rgba(109, 109, 109, 0.08);
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #ececec;
  box-sizing: border-box;
  .title {
    padding-top: 35px;
  }
  .table-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 25px;
    .price {
      width: 181px;
    }
    .usd-price {
      width: 179px;
    }
    .from {
      width: 164px;
    }
    .action {
      width: 158px;
    }
  }
  .data {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .data-main {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 32px;
      .price {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 181px;
        .price-coin {
          margin-right: 14px;
          width: 40px;
          height: 40px;
          background: #949798;
          border-radius: 50%;
          opacity: 0.4;
        }
      }
      .usd-price {
        width: 179px;
      }
      .from {
        width: 164px;
      }
      .action {
        padding: 1px;
        width: 120px;
        height: 48px;
        box-sizing: border-box;
        border-radius: 10px;
        background-image: -webkit-linear-gradient(
          180deg,
          rgba(255, 62, 253, 1),
          rgba(128, 107, 255, 1)
        );
        .action-in {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          background: white;
          border: none;
        }
      }
    }
  }
}
</style>
