<template>
  <!-- NFT商品属性组件 -->
  <div class="main" v-for="(item, index) in properData" :key="index">
    <div class="main_in">
      <div class="features-key fs16 pr400 fc151517">{{ item.featuresKey }}</div>
      <div class="features-value fs18 psm600 fc151517">
        {{ item.featuresValue }}
      </div>
      <div class="proportion fs14 pr400 fc949798">{{ item.proportion }}</div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
const properData = ref([
  { featuresKey: "Background", featuresValue: "Powder Blue", proportion: "13% have this trait" },
  { featuresKey: "Background", featuresValue: "Powder Blue", proportion: "14% have this trait" },
  { featuresKey: "Background", featuresValue: "Powder Blue", proportion: "15% have this trait" },
  { featuresKey: "Background", featuresValue: "Powder Blue", proportion: "16% have this trait" },
  { featuresKey: "Background", featuresValue: "Powder Blue", proportion: "17% have this trait" },
  { featuresKey: "Background", featuresValue: "Powder Blue", proportion: "18% have this trait" },
])
</script>
<style lang="less" scoped>
.main {
  margin-top: 18px;
  width: 156px;
  height: 109px;
  box-sizing: border-box;
  padding: 2px;
  border-radius: 10px;
  background-image: -webkit-linear-gradient(
    180deg,
    rgba(255, 62, 253, 1),
    rgba(128, 107, 255, 1)
  );
  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    margin-top: 0px !important;
  }
  .main_in {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: white;
  }
}
</style>
