import { createRouter, createWebHistory } from 'vue-router'
import Index from '../views/index/Index.vue'
import Market from '../views/market/Index.vue'
import Login from '../views/login/Index.vue'
import Price from '../views/user-buy/price.vue'
import Auction from '../views/user-buy/auction.vue'
import Purchase from '../views/purchase/Index.vue'
import PersonCenter from '../views/person/Index.vue'
import ItemsDetail from '../views/person/ItemsDetail.vue'

const routes = [
  // 首页
  {
    name: 'index',
    path: '/',
    component: Index
  },
  // 市场
  {
    name: 'market',
    path: '/market',
    component: Market,
  },
  // 登录
  {
    name: 'login',
    path: '/login',
    component: Login
  },
  // 定价形式购买
  {
    name: 'price',
    path: '/price',
    component: Price
  },
  // 拍卖形式购买
  {
    name: 'auction',
    path: '/auction',
    component: Auction
  },
  // 购入页面 
  {
    name: 'purchase',
    path: '/purchase',
    component: Purchase
  },
  // 个人中心
  {
    name: 'personCenter',
    path: '/personCenter',
    component: PersonCenter,
  },
  // 个人中心 我持有的 详情页面 
  {
    name: 'itemsDetail',
    path: '/itemsDetail',
    component: ItemsDetail
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router