<template>
  <!-- 定价交易 -->
  <Header></Header>
  <div class="price-main">
    <!-- 定价左边一列 -->
    <div class="price-left">
      <!-- NFT图片 -->
      <div class="nft-img">
        <img src="../../assets/img/nft-item.png" alt="" />
      </div>
      <!-- NFT所属属性 -->
      <div class="nft-properties">
        <div class="title">
          <div class="fs28 psm600 fc151517">Properties</div>
          <img src="../../assets/icon/bottom-arrow.png" alt="" />
        </div>
        <div class="properties">
          <Properties></Properties>
        </div>
      </div>
      <!-- NFT个人详细信息 -->
      <div class="nft-official">
        <div class="title">
          <div class="fs28 psm600 fc151517">About Cheers Up Official</div>
          <img src="../../assets/icon/bottom-arrow.png" alt="" />
        </div>
        <div class="user-desc">
          <UserDesc></UserDesc>
        </div>
      </div>
      <!-- NFT商品详细信息 -->
      <div class="nft-detail">
        <div class="title">
          <div class="fs28 psm600 fc151517">Details</div>
          <img src="../../assets/icon/bottom-arrow.png" alt="" />
        </div>
        <div class="goods-desc">
          <GoodsDesc></GoodsDesc>
        </div>
      </div>
    </div>
    <!-- 定价右边一列 -->
    <div class="price-right">
      <!-- 主要区域 -->
      <div class="nft-main-area">
        <NFTMain></NFTMain>
      </div>
      <!-- 商品所有购买记录 -->
      <div class="nft-offers">
        <Offers></Offers>
      </div>
    </div>
  </div>
  <!-- 商品推荐 -->
  <div class="more-Collection">
    <div class="title fs48 pbbold fc151517">More From This Collrcyion</div>
    <div class="collect-items">
      <div
        class="items-main"
        v-for="(item, index) in nftItemsData"
        :key="index"
      >
        <div class="item-img">
          <img :src="item.itemImg" alt="" />
        </div>
        <div class="item-desc">
          <div class="user-img">
            <img src="../../assets/img/user-head.png" alt="" />
          </div>
          <div class="item-name fs22 psm600 fc151517">{{ item.itemName }}</div>
          <div class="user-name fs16 pr400 fc949798">
            by <span>mihedian</span>
          </div>
          <div class="desc fs16 pr400 fc949798">
            <!-- {{ item.description }} -->
            Market support: cash, lage, contracts, derivatives and other
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer></Footer>
</template>
<script setup>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import Properties from './components/Properties.vue' //属性组件
import UserDesc from './components/UserDesc.vue' //用户描述组件
import GoodsDesc from './components/GoodsDetail.vue' //商品描述组件
import NFTMain from './components/NFTMain.vue' //NFT主要功能组件
import Offers from './components/Offers.vue' //商品购买记录组件
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const router = new useRouter()

// 推荐商品数据 测试数据
const nftItemsData = ref([
  { itemImg: require('../../assets/img/nft-item.png'), userImg: require('../../assets/img/user-head.png'), itemName: 'Win Nft Hero1', userName: 'mihedian', desc: 'Market support: cash, lage, contracts, derivatives and other' },
  { itemImg: require('../../assets/img/nft-item.png'), userImg: require('../../assets/img/user-head.png'), itemName: 'Win Nft Hero2', userName: 'mihedian', desc: 'Market support: cash, lage, contracts, derivatives and other' },
  { itemImg: require('../../assets/img/nft-item.png'), userImg: require('../../assets/img/user-head.png'), itemName: 'Win Nft Hero3', userName: 'mihedian', desc: 'Market support: cash, lage, contracts, derivatives and other' },
  { itemImg: require('../../assets/img/nft-item.png'), userImg: require('../../assets/img/user-head.png'), itemName: 'Win Nft Hero4', userName: 'mihedian', desc: 'Market support: cash, lage, contracts, derivatives and other' },
])

</script>
<style lang="less" scoped>
.price-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 180px;
  width: 1300px;
  height: 100%;
  .price-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 500px;
    height: 100%;
    .nft-img {
      width: 500px;
      height: 500px;
      border-radius: 10px 10px 10px 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .nft-properties {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 41px;
      width: 100%;
      height: 308px;
      .title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 37px;
        img {
          width: 14px;
          height: 8px;
        }
      }
      .properties {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        height: 237px;
      }
    }
    .nft-official {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 55px;
      width: 100%;
      height: 190px;
      .title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 37px;
        img {
          width: 14px;
          height: 8px;
        }
      }
      .user-desc {
        margin-top: 32px;
      }
    }
    .nft-detail {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 55px;
      width: 100%;
      height: 240px;
      .title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 37px;
        img {
          width: 14px;
          height: 8px;
        }
      }
      .goods-desc {
        margin: 20px 0 0 17px;
      }
    }
  }
  .price-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 718px;
    height: 100%;
    .nft-offers {
      margin-top: 41px;
    }
  }
}
.more-Collection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  margin-top: 160px;
  margin-bottom: 95px;
  width: 1300px;
  height: 560px;
  .title {
    margin: 0 auto;
  }
  .collect-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 60px;
    .items-main {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 300px;
      height: 420px;
      box-shadow: 7px 7px 26px 5px rgba(109, 109, 109, 0.08);
      border-radius: 10px 10px 10px 10px;
      border: 1px solid #ececec;
      .item-img {
        width: 301px;
        height: 240px;
        border-radius: 10px 10px 0px 0px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px 10px 0px 0px;
        }
      }
      .item-desc {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        .user-img {
          position: absolute;
          top: -30px;
          width: 60px;
          height: 60px;
          border: 2px solid #ffffff;
          border-radius: 50%;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .item-name {
          margin-top: 37px;
        }
        .user-name {
          span {
            color: #dc19da;
          }
        }
        .desc {
          margin-top: 16px;
          width: 264px;
          text-align: center;
        }
      }
    }
  }
}
</style>
