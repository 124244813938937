<template>
  <!-- 用户个人中心弹窗 -->
  <div class="user-dialog-main">
    <!-- 头像区域 -->
    <div class="user-area">
      <div class="head-img">
        <img src="../../assets/img/user-head.png" alt="" />
      </div>
      <div class="user-address pp600 fs24 fc000000">0x8A916...D13Z</div>
      <div class="close">
        <img src="../../assets/icon/close.png" alt="" @click="closeDialog" />
      </div>
    </div>
    <!-- 我的持有 -->
    <div class="my-item-area pp600 fs28 fc000000">My Items</div>
    <!-- 我的喜爱 -->
    <div class="favorites-area pp600 fs28 fc000000">Favorites</div>
    <!-- 我的收藏 -->
    <div class="collect-area pp600 fs28 fc000000" @click="collectClick">
      My collections
    </div>
    <!-- 个人设置 -->
    <div class="setting-area pp600 fs28 fc000000">Settings</div>
    <!-- 主题切换 -->
    <div class="mode-area pp600 fs28 fc000000">Night Mode</div>
    <!-- 钱包区域 -->
    <div class="my-wallet-area">
      <!-- 标题 -->
      <div class="pp300 fs16 fc000000">My Wallet</div>
      <div class="metamask-wallet">
        <div class="wallet-center">
          <!-- 钱包logo 地址 复制 退出 -->
          <div class="address-logout">
            <div class="metamask"></div>
            <div class="address">
              <div class="pp300 fs14 fc000000">Ethereum</div>
              <div class="pp500 fs16 fc000000">0x8A916...D13Z</div>
            </div>
            <div class="copy"></div>
            <div class="logout"></div>
          </div>
          <!-- 钱包余额展示 -->
          <div class="amount-cion">
            <div class="eth">
              <div class="logo"></div>
              <div class="amount pp500 fs14 fc000000">42.41 ETH</div>
            </div>
            <div class="line"></div>
            <div class="fra">
              <div class="logo"></div>
              <div class="amount pp500 fs14 fc000000">91,394.13 FRA</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router'

const router = new useRouter()

const emits = defineEmits(['userRouter'])
const closeDialog = () => {
  emits('userRouter')
}
const collectClick = () => {
  router.push('/personCenter')
}
</script>
<style lang="less" scoped>
.user-dialog-main {
  width: 326px;
  height: 700px;
  .user-area {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-top: 33px;
    width: 271px;
    height: 66px;
    .head-img {
      width: 66px;
      height: 66px;
      margin-right: 16px;
      img {
        width: 66px;
        height: 66px;
      }
    }
    .user-address {
      cursor: pointer;
    }
    .close {
      position: absolute;
      top: -17px;
      right: -64px;
      width: 28px;
      height: 28px;
      img {
        width: 28px;
        height: 28px;
      }
    }
  }
  .my-item-area {
    margin-top: 20px;
    cursor: pointer;
  }
  .favorites-area {
    margin-top: 12px;
    cursor: pointer;
  }
  .collect-area {
    margin-top: 12px;
    cursor: pointer;
  }
  .setting-area {
    margin-top: 12px;
    cursor: pointer;
  }
  .mode-area {
    margin-top: 12px;
    cursor: pointer;
  }
  .my-wallet-area {
    margin-top: 32px;
    .metamask-wallet {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 16px;
      width: 326px;
      height: 208px;
      background: #ebedee;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      .wallet-center {
        width: 294px;
        height: 174px;
        .address-logout {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          height: 38px;
          .metamask {
            width: 38px;
            height: 38px;
            background-color: gray;
          }
          .address {
            margin-left: 15px;
          }
          .copy {
            margin-left: 42px;
            width: 32px;
            height: 32px;
            background: #ffffff;
            border-radius: 6.4px;
            cursor: pointer;
          }
          .logout {
            margin-left: 8px;
            width: 32px;
            height: 32px;
            background: #ffffff;
            border-radius: 6.4px;
            cursor: pointer;
          }
        }
        .amount-cion {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          margin-top: 17px;
          padding-left: 16px;
          width: 100%;
          height: 119px;
          background: #ffffff;
          border-radius: 8px;
          box-sizing: border-box;
          .eth {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            .logo {
              width: 28px;
              height: 28px;
              background-color: gray;
              border-radius: 50%;
            }
            .amount {
              margin-left: 12px;
            }
          }
          .line {
            width: 262px;
            height: 1px;
            background: rgba(0, 0, 0, 0.1);
          }
          .fra {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            .logo {
              width: 28px;
              height: 28px;
              background-color: gray;
              border-radius: 50%;
            }
            .amount {
              margin-left: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
