<template>
  <!-- 支付完成弹窗 -->
  <div class="payment-dialog" v-show="paymentComplete">
    <div class="main">
      <!-- 关闭按钮 -->
      <div class="close">
        <img src="../../assets/icon/close.png" alt="" @click="closeClick" />
      </div>
      <!-- 标题 -->
      <div class="complete-title pp600 fs36 fc000000">
        Complete your listing
      </div>
      <!-- 主体 居中对齐 -->
      <div class="contant">
        <div class="goodsImg">
          <img src="../../assets/img/sale-nft.png" alt="" />
        </div>
        <div class="goods-desc pr400 fs16 fc949798">
          <span>Cherrs UP #2953</span> from the
          <span>Cheers UP</span> collection has been listed for sale
        </div>
        <div class="share-to">
          <div class="line"></div>
          <div class="title fs24 pm500 fc151517">SHARE TO</div>
          <div class="line"></div>
        </div>
        <div class="social-center">
          <div class="dont-know">
            <img src="../../assets/logo/dont-know.png" alt="" />
          </div>
          <div class="twitter">
            <img src="../../assets/logo/twitter.png" alt="" />
          </div>
          <div class="discord">
            <img src="../../assets/logo/discard.png" alt="" />
          </div>
        </div>
        <div class="view-listing">
          <button class="pp500 fs20 fcFFFFFF" @click="viewListClick">
            view listing
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'

const props = defineProps(['paymentComplete'])
const emits = defineEmits(['payCompleteClose'])

const closeClick = () => {
  emits('payCompleteClose')
}

const viewListClick = () => { }

</script>
<style lang="less" scoped>
.payment-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  .main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-293px, -368.5px);
    width: 586px;
    height: 737px;
    background: #fafbfc;
    box-shadow: 7px 7px 26px 5px rgba(109, 109, 109, 0.08);
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    .close {
      align-self: flex-end;
      margin: 30px 28px 0 0;
      img {
        width: 26px;
        height: 26px;
        cursor: pointer;
      }
    }
    .complete-title {
      margin-left: 32px;
    }
    .contant {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 34px;
      .goodsImg {
        width: 317px;
        height: 317px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .goods-desc {
        margin-top: 24px;
        width: 377px;
        height: 48px;
        text-align: center;
        span {
          color: #00aaff;
        }
      }
      .share-to {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        height: 22px;
        .line {
          width: 160px;
          height: 1px;
          background: #e6e6e6;
          border-radius: 0px 0px 0px 0px;
        }
        .title {
          margin-left: 20px;
          margin-right: 20px;
        }
      }
      .social-center {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 26px;
        width: 210px;
        height: 56px;
        background: #ffffff;
        border-radius: 10px 10px 10px 10px;
        border: 1px solid #e6e6e6;
        box-sizing: border-box;
        .dont-know {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 70px;
          height: 100%;
          border-right: 1px solid #e6e6e6;
          img {
            width: 34px;
            height: 28px;
            cursor: pointer;
          }
        }
        .twitter {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 70px;
          height: 100%;
          border-right: 1px solid #e6e6e6;
          img {
            width: 34px;
            height: 28px;
            cursor: pointer;
          }
        }
        .discord {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 70px;
          height: 100%;
          img {
            width: 34px;
            height: 28px;
            cursor: pointer;
          }
        }
      }
      .view-listing {
        margin-top: 20px;
        width: 198px;
        height: 52px;
        button {
          width: 198px;
          height: 52px;
          background: linear-gradient(133deg, #ff3efd 0%, #9b8bff 100%);
          border-radius: 10px 10px 10px 10px;
          opacity: 1;
          border: none;
        }
      }
    }
  }
}
</style>
