<template>
  <div
    class="items-main"
    v-for="(item, index) in marketList"
    :key="index"
    @click="itemsRouter"
  >
    <div class="item-img">
      <img :src="item.image" alt="" />
    </div>
    <div class="item-desc">
      <div class="user-img">
        <img src="../../../assets/img/user-head.png" alt="" />
      </div>
      <div class="item-name fs22 psm600 fc151517">{{ item.name }}</div>
      <div class="user-name fs16 pr400 fc949798">by <span>mihedian</span></div>
      <div class="desc fs16 pr400 fc949798">
        <!-- {{ item.description }} -->
        Market support: cash, lage, contracts, derivatives and other
      </div>
    </div>
  </div>
</template>
<script setup>
import gql from 'graphql-tag'
import { useQuery } from '@vue/apollo-composable'
import { ref, getCurrentInstance, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const route = new useRoute()
const router = new useRouter()

// 路由跳转名称 哪个分类 默认查全部
const routeType = route.params.type

const { proxy } = getCurrentInstance()

const CHARACTERS_QUERY = gql`
query AllOrder {
  orderEntities {
    changeRate
    endTime
    id
    minPrice
    nftToken
    nftType
    orderOwner
    orderType
    price
    startTime
    tokenAmount
    token
    tokenId
  }
}`

// 市场所有数据，统一push
const marketList = ref([])
// thegraph查询的数据
const marketResult = ref()
const { result, loading } = useQuery(CHARACTERS_QUERY);
watch(result, async (newValue) => {
  marketResult.value = newValue.orderEntities
  for (var i in marketResult.value) {
    // thegraph查询的数据，通过接口获取到真正的市场数据
    const { data } = await proxy.$api.nftDetail(marketResult.value[i].tokenId)
    marketList.value.push(data)
  }
})

// 所有NFT商品详情数据 测试数据
// const nftItemsData = ref([
//   { itemImg: require('../../../assets/img/nft-item.png'), userImg: require('../../../assets/img/user-head.png'), itemName: 'Win Nft Hero1', userName: 'mihedian', desc: 'Market support: cash, lage, contracts, derivatives and other' },
//   { itemImg: require('../../../assets/img/nft-item.png'), userImg: require('../../../assets/img/user-head.png'), itemName: 'Win Nft Hero2', userName: 'mihedian', desc: 'Market support: cash, lage, contracts, derivatives and other' },
//   { itemImg: require('../../../assets/img/nft-item.png'), userImg: require('../../../assets/img/user-head.png'), itemName: 'Win Nft Hero3', userName: 'mihedian', desc: 'Market support: cash, lage, contracts, derivatives and other' },
//   { itemImg: require('../../../assets/img/nft-item.png'), userImg: require('../../../assets/img/user-head.png'), itemName: 'Win Nft Hero4', userName: 'mihedian', desc: 'Market support: cash, lage, contracts, derivatives and other' },
//   { itemImg: require('../../../assets/img/nft-item.png'), userImg: require('../../../assets/img/user-head.png'), itemName: 'Win Nft Hero5', userName: 'mihedian', desc: 'Market support: cash, lage, contracts, derivatives and other' }
// ])

const itemsRouter = () => {
  router.push('/price')
}

</script>
<style lang="less" scoped>
.items-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 40px;
  width: 300px;
  height: 420px;
  box-shadow: 7px 7px 26px 5px rgba(109, 109, 109, 0.08);
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #ececec;
  cursor: pointer;
  .item-img {
    width: 301px;
    height: 240px;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px 10px 0px 0px;
      transition: all 1s ease;
      &:hover {
        transform: scale(1.1, 1.1);
      }
    }
  }
  .item-desc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    .user-img {
      position: absolute;
      top: -30px;
      width: 60px;
      height: 60px;
      border: 2px solid #ffffff;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .item-name {
      margin-top: 37px;
    }
    .user-name {
      span {
        color: #dc19da;
      }
    }
    .desc {
      margin-top: 16px;
      width: 264px;
      text-align: center;
    }
  }
}
</style>
