import { createApp, provide, h } from 'vue'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { ApolloClient, InMemoryCache } from '@apollo/client/core'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入less样式统一文件
import './assets/less/index.less'
// 引入element UI组件
import 'element-plus/es/components/message/style/css'
import { ElMessage } from 'element-plus'

// 加载接口文件
import api from './utils/api'

// 加载语言文件 简体 繁体 英文 韩文
import vueI18n from './lang/index'

const cache = new InMemoryCache()
// Apollo Client是一个成熟的GraphQL客户端和状态管理库
const apolloClient = new ApolloClient({
  cache,
  uri: 'https://api.thegraph.com/subgraphs/name/zhaopeng331/nft-market', // thegraph url
})

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient)
  },
  render: () => h(App),
})

app.use(store)
  .use(router)
  .use(vueI18n)
  .mount('#app')


app.config.globalProperties.$api = api
app.config.globalProperties.$message = ElMessage