import {
  createI18n
} from "vue-i18n";
import zh from "./zh";
import en from "./en";
import tw from "./tw";
import kr from "./kr";

const i18n = createI18n({
  legacy: false, //处理报错Uncaught (in promise) SyntaxError: Not available in legacy mode (at message-compiler.esm-bundler.js:54:19)
  globalInjection: true, //全局生效$t
  locale: localStorage.getItem('language') || 'en', //默认语言为英文
  messages: {
    zh,
    en,
    tw,
    kr
  },
});

export default i18n;