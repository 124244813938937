<template>
  <!-- NFT详情主要区域 -->
  <div class="nft-main">
    <!-- 名字 喜爱 分享 查看 描述-->
    <div class="name-desc-other">
      <div class="name">
        <div class="goods-name fs60 psm600 fc151517">{{ goodsName }}</div>
        <div class="goods-love">
          <img src="../../../assets/icon/love-black.png" class="love-img" />
          <div class="love-num fs16 pr400 fc949798">{{ loveNum }}k</div>
          <img src="../../../assets/icon/share-black.png" class="share-img" />
        </div>
      </div>
      <div class="views fs16 pr400 fc949798">
        <img src="../../../assets/icon/love-black.png" alt="" />
        {{ viewsNum }}k Views
      </div>
      <div class="desc fs16 pr400 fc949798">
        {{ goodsDesc }}
      </div>
    </div>
    <!-- 拥有人 收藏集 铸造者 -->
    <div class="owner-collect-creater">
      <div class="owner-area">
        <div class="fs16 pr400 fc949798">Current owner</div>
        <div class="curr-img-address">
          <img src="../../../assets/img/user-head.png" alt="" />
          <div class="address fs20 psm600 fc151517">
            {{ ownerSix }}...{{ ownerFour }}
          </div>
        </div>
      </div>
      <div class="collect-area">
        <div class="fs16 pr400 fc949798">Collection</div>
        <div class="coll-img-address">
          <img src="../../../assets/img/user-head.png" alt="" />
          <div class="address fs20 psm600 fc151517">
            {{ collSix }}...{{ collFour }}
          </div>
        </div>
      </div>
      <div class="creater-area">
        <div class="fs16 pr400 fc949798">Creator</div>
        <div class="crea-img-address">
          <img src="../../../assets/img/user-head.png" alt="" />
          <div class="address fs20 psm600 fc151517">
            {{ creaSix }}...{{ creaFour }}
          </div>
        </div>
      </div>
    </div>
    <!-- 上架区域 -->
    <div class="price-buy-area">
      <button class="approve fs20 pr500 fcFFFFFF">Approve</button>
      <button class="put-on-shelves fs20 pr500 fcFFFFFF">Put On Shelves</button>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const router = new useRouter()

// 商品名称
const goodsName = ref('WIN NFT HE')
// 喜爱数量
const loveNum = ref('13.4')
// 观看数量
const viewsNum = ref('14.5')
// 商品描述
const goodsDesc = ref('Market support: cash, lage, contracts, derivatives and otheMarket support: cash, lage, contracts, derivatives and other')
// 拥有者地址 前6
const ownerSix = ref('0x3781')
// 拥有者地址 后4
const ownerFour = ref('c7g1')
// 收藏者地址 前6
const collSix = ref('0x3742')
// 收藏者地址 后4
const collFour = ref('c7g2')
// 铸造者地址 前6
const creaSix = ref('0x3783')
// 铸造者地址 后4
const creaFour = ref('c7g3')

</script>
<style lang="less" scoped>
.nft-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 500px;
  .name-desc-other {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 173px;
    .name {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      height: 65px;
      .goods-name {
        width: 100%;
        height: 65px;
      }
      .goods-love {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-right: 24px;
        width: 117px;
        height: 100%;
        .love-img {
          width: 21px;
          height: 18.84px;
          cursor: pointer;
        }
        .love-num {
          margin-left: 7px;
          cursor: default;
        }
        .share-img {
          margin-left: 20px;
          width: 28px;
          height: 28px;
          cursor: pointer;
        }
      }
    }
    .views {
      img {
        margin-right: 9px;
      }
    }
    .desc {
      line-height: 20px;
    }
  }
  .owner-collect-creater {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
    width: 100%;
    height: 70px;
    .owner-area {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 190px;
      height: 100%;
      .curr-img-address {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        img {
          width: 42px;
          height: 42px;
        }
      }
    }
    .collect-area {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 190px;
      height: 100%;
      .coll-img-address {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        img {
          width: 42px;
          height: 42px;
        }
      }
    }
    .creater-area {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 190px;
      height: 100%;
      .crea-img-address {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        img {
          width: 42px;
          height: 42px;
        }
      }
    }
  }
  .price-buy-area {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 50px;
    width: 100%;
    height: 120px;
    background: #ffffff;
    border: 1px solid #ececec;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 7px 7px 26px 5px rgba(109, 109, 109, 0.08);
    .approve {
      width: 220px;
      height: 52px;
      background: linear-gradient(133deg, #ff3efd 0%, #9b8bff 100%);
      border-radius: 10px 10px 10px 10px;
      border: none;
    }
    .put-on-shelves {
      width: 220px;
      height: 52px;
      background: linear-gradient(133deg, #ff3efd 0%, #9b8bff 100%);
      border-radius: 10px 10px 10px 10px;
      border: none;
    }
  }
}
</style>
