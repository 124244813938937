<template>
  <!-- 商品描述组件 -->
  <div class="goods-main">
    <div class="goods-left fs14 pr400 fc949798">
      <div>Contract Address</div>
      <div>Token Id</div>
      <div>Token Standard</div>
      <div>Blockchain</div>
      <div>Metadata</div>
      <div>Creator Fees</div>
    </div>
    <div class="goods-right fs14 pr400 fc151517">
      <div>{{ goodsDetails.contractAddress }}</div>
      <div>{{ goodsDetails.tokenId }}</div>
      <div>{{ goodsDetails.tokenStandard }}</div>
      <div>{{ goodsDetails.Blockchain }}</div>
      <div>{{ goodsDetails.Metadata }}</div>
      <div>{{ goodsDetails.creatorFees }}</div>
    </div>
  </div>
</template>
<script setup>
import { reactive } from 'vue'

const goodsDetails = reactive({
  contractAddress: "0x7yudm...78d",
  tokenId: "89080",
  tokenStandard: "ERC-721",
  Blockchain: "Ethereum",
  Metadata: "Metadata",
  creatorFees: "2.5%"
})

</script>
<style lang="less" scoped>
.goods-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 191px;
  .goods-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .goods-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    div {
      align-self: flex-end;
    }
  }
}
</style>
