
import axios from "axios";
import store from '@/store';

// const env = process.env.NODE_ENV;
// let baseURL = process.env.VUE_APP_BASEURL;

export function request(config) {
  const instance = axios.create({
    baseURL: "https://weasuki.s3.us-west-1.amazonaws.com/",
    withCredentials: false, // 表示跨域请求时是否需要使用凭证
    timeout: 20000,
  })
  // 请求拦截器
  instance.interceptors.request.use(config => {
    if (!config.data) {
      config.headers = {}
    }

    // const user = store.state.userInfo.userInfo
    // let token = null;
    // if (user && user.token) {
    //   token = user.token;
    // }
    // config.headers.token = token;

    return config
  }, err => Promise.reject(err))

  // 响应拦截器
  instance.interceptors.response.use(res => {
    if (res.data.code !== 0) {
    }
    return res
  }, err => {
    return Promise.reject(err)
  })
  return instance(config)
}

export function $get(url, data) {
  return request({
    url: url,
    method: 'get',
    params: data,
  });
}

export function $post(url, data) {
  return request({
    url: url,
    method: 'post',
    data
  });
}

export function $put(url, data) {
  return request({
    url: url,
    method: 'put',
    data
  });
}